import { OpenAPIClientAxios } from 'openapi-client-axios';
import type { OpenAPIClient } from 'openapi-client-axios/client';
import { type RegisterRequest } from './types';

let client: Promise<OpenAPIClient> | null = null;

export const URL = window.location.port === '3000' ? 'http://localhost:8000' : '';

function init(user: string, pass: string): void {
    if (!(client instanceof Promise)) {
        client = new OpenAPIClientAxios({
            definition: `${URL}/definition`,
            axiosConfigDefaults: {
                auth: {
                    username: user,
                    password: pass,
                },
            },
        }).init();
    }
}

export const getParticipants = async (user: string, pass: string): Promise<Record<string, RegisterRequest>> => {
    init(user, pass);
    if (client instanceof Promise) {
        const normalClient = await client;
        return (await normalClient.participants()).data as unknown as Record<string, RegisterRequest>;
    }
    throw new Error('Init not performed');
};

export const deleteParticipant = async (user: string, pass: string, email: string): Promise<void> => {
    init(user, pass);

    if (client instanceof Promise) {
        const normalClient = await client;
        await normalClient.participantDelete(email);
    }
};

export const updateParticipant = async (
    user: string,
    pass: string,
    email: string,
    data: Partial<RegisterRequest>,
): Promise<void> => {
    init(user, pass);

    if (client instanceof Promise) {
        const normalClient = await client;
        await normalClient.participantPatch(email, data);
    }
};

export const deleteParticipantPublicKey = async (user: string, pass: string, email: string): Promise<void> => {
    init(user, pass);

    if (client instanceof Promise) {
        const normalClient = await client;
        await normalClient.participantPublicKeyDelete(email);
    }
};
