import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { MdCheck, MdClose, MdMail, MdPrint } from 'react-icons/md';
import { updateParticipant } from '../apiAdmin';
import { type RegisterRequest } from '../types';
import PositionSelector from './PositionSelector';

interface RaspiApprovalProps {
    onClose: (printed?: boolean) => void;
    data: RegisterRequest;
    login: string;
    password: string;
}

async function approveRaspi(login: string, password: string, email: string): Promise<void> {
    await updateParticipant(login, password, email, { raspiApproved: true });
}

async function declineRaspi(login: string, password: string, email: string, reason?: string): Promise<void> {
    await updateParticipant(login, password, email, { raspiApproved: false, raspiDeclinedReason: reason || '' });
}

const RaspiApproval = ({ onClose, data, login, password }: RaspiApprovalProps): React.JSX.Element => {
    const [declineReason, setDeclineReason] = React.useState('');
    const [dhlNumber, setDhlNumber] = React.useState(typeof data.raspiSent === 'string' ? data.raspiSent : '');
    const [showPositionSelector, setShowPositionSelector] = React.useState<RegisterRequest | null>(null);
    // we can have following states:
    // raspi: false - raspi is not requested, nothing to do
    // raspi: true, raspiApproved: null - raspi requested, but no decision yet
    // raspi: true, raspiApproved: false, raspiDeclinedReason: "reason" - raspi requested, but declined with or without reason
    // raspi: true, raspiApproved: true, raspiSent: false - raspi requested, approved, but not yet shipped
    // raspi: true, raspiApproved: true, raspiSent: true | DHL number - raspi requested, approved, and shipped
    let content: React.JSX.Element | string | null = null;
    const style: React.CSSProperties = {};
    if (!data.raspi) {
        content = `Raspberry Pi wurde vom ${data.name} nicht angefordert`;
        style.color = 'grey';
    } else if (data.raspiApproved === null || data.raspiApproved === undefined) {
        content = (
            <div>
                Raspberry Pi wurde vom
                <span style={{ marginRight: 4, marginLeft: 4, fontWeight: 'bold' }}>{data.name}</span>
                angefordert.
                <br />
                Bitte entscheiden:
                <Form.Control
                    type="text"
                    placeholder="Grund für Ablehnung"
                    value={declineReason}
                    onChange={e => setDeclineReason(e.target.value)}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        gap: 8,
                        marginTop: 8,
                    }}
                >
                    <Button
                        style={{ backgroundColor: 'green' }}
                        disabled={!!declineReason}
                        onClick={async () => {
                            await approveRaspi(login, password, data.email);
                            onClose(true);
                        }}
                    >
                        <MdCheck style={{ marginRight: 8 }} />
                        Freigeben
                    </Button>
                    <Button
                        style={{ backgroundColor: 'red' }}
                        disabled={!declineReason}
                        onClick={async () => {
                            await declineRaspi(login, password, data.email, declineReason);
                            onClose(true);
                        }}
                    >
                        <MdClose style={{ marginRight: 8 }} />
                        Ablehnen
                    </Button>
                </div>
            </div>
        );
    } else if (!data.raspiApproved) {
        content = (
            <div>
                Raspberry Pi wurde vom
                <span style={{ marginRight: 4, marginLeft: 4, fontWeight: 'bold' }}>{data.name}</span>
                angefordert. Aber es wurde nicht genehmigt
                {data.raspiDeclinedReason ? (
                    <>
                        :<div style={{ color: 'black' }}>{data.raspiDeclinedReason}</div>
                    </>
                ) : (
                    ''
                )}
            </div>
        );
        style.color = 'red';
    } else if (data.raspiApproved && !data.raspiSent) {
        content = (
            <div>
                Raspberry Pi wurde vom
                <span style={{ marginLeft: 8, marginRight: 8 }}>{data.name}</span>
                angefordert. Es wurde genehmigt, aber noch nicht versendet.
                <br />
                Bitte geben Sie die Sendungsnummer ein:
                <Form.Control
                    type="text"
                    placeholder="DHL Sendungsnummer"
                    value={dhlNumber}
                    onChange={e => setDhlNumber(e.target.value)}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 8,
                        marginTop: 8,
                    }}
                >
                    <Button
                        style={{ marginTop: 8, backgroundColor: 'yellow', color: 'black' }}
                        onClick={() => setShowPositionSelector(data)}
                    >
                        <MdPrint style={{ marginRight: 8 }} />
                        Drucke Adressetikett
                    </Button>
                    <Button
                        style={{ marginTop: 8 }}
                        onClick={async () => {
                            if (dhlNumber) {
                                await updateParticipant(login, password, data.email, { raspiSent: dhlNumber });
                            } else {
                                await updateParticipant(login, password, data.email, {
                                    raspiSent: new Date().toISOString(),
                                });
                            }

                            onClose(true);
                        }}
                    >
                        <MdMail style={{ marginRight: 8 }} />
                        Markiere als Versendet
                    </Button>
                </div>
            </div>
        );
        style.color = 'green';
    } else if (data.raspiApproved && data.raspiSent) {
        content = `Raspberry Pi wurde vom ${data.name} angefordert. Es wurde genehmigt, und${
            typeof data.raspiSent === 'string' &&
            (data.raspiSent.startsWith('2024-') || data.raspiSent.startsWith('2025-'))
                ? ` am ${new Date(data.raspiSent).toLocaleString()}`
                : data.raspiSent && typeof data.raspiSent === 'string'
                  ? ` mit DHL ${data.raspiSent}`
                  : ''
        } verschickt`;
        style.color = 'green';
    }

    if (showPositionSelector) {
        return (
            <PositionSelector
                onClose={(printed?: boolean) => {
                    setShowPositionSelector(null);
                    if (printed) {
                        // request list
                        setTimeout(() => onClose(true), 1000);
                    }
                }}
                data={data}
                login={login}
                password={password}
            />
        );
    }
    return (
        <Modal
            show={!0}
            onHide={() => onClose()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Raspberry Pi Zustand</Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div style={{ ...style, padding: 10 }}>{content}</div>
            </Modal.Body>
        </Modal>
    );
};

export default RaspiApproval;
