import React, { useEffect, useRef, useState } from 'react';
import MarkdownIt from 'markdown-it';

const FritzBox = (): React.JSX.Element => {
    const mdRef = useRef<MarkdownIt>();
    const [mdText, setMdText] = useState<string | null>(null);

    useEffect(() => {
        mdRef.current = new MarkdownIt({ html: true });
        void fetch('fritzbox.md')
            .then(response => response.text())
            .then(text => setMdText(text));
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 24,
                width: '100%',
                textAlign: 'justify',
            }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: mdText && mdRef.current ? mdRef.current.render(mdText) : 'Lade...' }}
        />
    );
};

export default FritzBox;
