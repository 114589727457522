import React from 'react';
import { Modal } from 'react-bootstrap';
import { URL } from '../apiAdmin';
import { type RegisterRequest } from '../types';

interface PositionsSelectorProps {
    onClose: (printed?: boolean) => void;
    data: RegisterRequest;
    login: string;
    password: string;
}

const RATIO = 1;

const onePartStyle: React.CSSProperties = {
    width: (297 * RATIO) / 2,
    height: (210 * RATIO) / 2,
};

function drawOnePart(
    data: RegisterRequest,
    position: number,
    lastSelected: string | null,
    onPrint: (pos: number) => void,
): React.JSX.Element {
    return (
        <div
            style={onePartStyle}
            onClick={() => onPrint(position)}
            className={`page-part ${lastSelected === position.toString() ? 'page-last-selected' : ''}`}
        >
            <div
                className="page-text"
                style={{ fontSize: 10 * RATIO }}
            >
                <div>{data.name}</div>
                <div>{data.addressLine1}</div>
                {data.addressLine2 ? <div>{data.addressLine2}</div> : null}
                <div>
                    <span style={{ marginRight: 8 }}>{data.addressZip}</span>
                    {data.addressCity}
                </div>
                <div>Deutschland</div>
                Stadt: Musterstadt
            </div>
        </div>
    );
}

const PositionSelector = ({ onClose, data, login, password }: PositionsSelectorProps): React.JSX.Element => {
    const position: string | null = window.localStorage.getItem('position');

    const onPrint = (pos: number): void => {
        window.localStorage.setItem('position', pos.toString());
        void fetch(`${URL}/api/v1/participant/shippingLabel/${data.email}?position=${pos}`, {
            headers: {
                Authorization: `Basic ${btoa(`${login}:${password}`)}`,
            },
        })
            .then(response => response.json())
            .then((dataWithCode: { code: string }) => {
                window.open(
                    `${URL}/api/v1/participant/shippingLabel/${data.email}?position=${pos}&code=${dataWithCode.code}`,
                    data.email,
                );
                onClose(true);
            });
    };

    return (
        <Modal
            show={!0}
            onHide={() => onClose()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Bitte einloggen</Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div style={{ width: 297 * RATIO, height: 210 * RATIO, marginBottom: 10 }}>
                    {drawOnePart(data, 0, position, onPrint)}
                    {drawOnePart(data, 1, position, onPrint)}
                    <br />
                    {drawOnePart(data, 2, position, onPrint)}
                    {drawOnePart(data, 3, position, onPrint)}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PositionSelector;
