import React, { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import MarkdownIt from 'markdown-it';

interface Block {
    header: string;
    content: string;
    hash?: string;
}

const faqBlocks: Block[] = [
    {
        header: 'Probleme nach Neuinstallation des Adapters.',
        content: `
Stellen Sie zunächst sicher, dass Sie die aktuelle Version des Adapters benutzen. 

Sollte es auch bei der aktuellen Version zu Problemen kommen, kontaktieren Sie uns bitte.
`,
        hash: 'adapter-reinstall',
    },
    {
        header: 'Deinstallation des Adapters',
        content: `
Bitte beachten Sie: Der öffentliche Schlüssel, der für die Kommunikation mit unserem Server notwendig ist, muss auf Ihrem Gerät gespeichert werden. 
Dieser Schlüssel wird unter 0_userdata.0.kisshomeResearchPublicKey persistiert, damit es bei Neuinstallationen des Adapters nicht zu Problemen kommt. 
Nach Deinstalltion muss dieser Schlüssel manuell gelöscht werden.  
`,
        hash: 'adapter-deinstall',
    },
    {
        header: 'In den Adaptereinstellungen unter Adressen lassen sich keine Änderungen speichern / Das Speichern ist ausgegraut.',
        content: `
Dieses Problem wurde behoben. Bitte stellen Sie sicher, dass Sie die aktuelle Version des Adapters benutzen. 
`,
        hash: 'no-saving-possible',
    },
    {
        header: 'Warum wird für einige Geräte keine MAC-Adresse angezeigt? / Kann die MAC Adresse von xxx.xxx.xxx.xxx nicht auflösen',
        content: `
Die zur Auswahl bereitgestellten Geräte werden anderen Adaptern entnommen, es ist möglich das sich darunter auch solche Geräte befinden die gerade offline bzw. nicht mehr im Netzwerk sind befinden.
Außerdem funktioniert die Bestimmung der MAC-Adressen nur für Geräte die mit Adaptern auf dem selben Host wie der kisshome-research Adapter verbunden sind.
`,
        hash: 'no-mac-address',
    },
    {
        header: 'Ich benutze Fritz!Box nicht als direktes Standardgateway. Kann ich teilnehmen?',
        content: `
Nein. Leider funktioniert das Mitschneiden des Verkehrs aktuell nur, wenn die Fritz!Box tatsächlich als Router geschaltet ist und der Verkehr der Geräte direkt über die Fritz!Box geht.
Unifi oder andere Konstellationen, bei denen etwa zusätzliche Router hinter der Fritz!Box geschaltet sind, funktionieren daher leider nicht.
`,
        hash: 'standard_gateway',
    },
    {
        header: 'Was bedeutet "Nur Verkehr von Geräten, nicht der Fritz!Box selbst darf aufgezeichnet werden"?',
        content: `
Die Fritzbox selbst steht nicht zum Aufzeichnen zur Verfügung da sie als Standard-Gateway fungiert und damit sämtlicher Verkehr der das Netz verlässt aufgezeichnet werden würde.
`,
        hash: 'no-fritzbox-traffic',
    },
    {
        header: 'Ich benutze ioBroker nicht. Kann ich trotzdem teilnehmen?',
        content: `
Ja! Sofern sie einen Raspberry-Pi haben, können Sie ioBroker hierauf einrichten. Anleitungen hierfür finden Sie online, bei Fragen stehen wir gerne zur Verfügung. 

Alternativ können Sie einen eingerichteten Raspberry Pi von uns anfragen, sofern wir noch welche zur Verfügung haben.
Füllen Sie hierzu das Registrierungsformular aus und geben Sie an, dass Sie für die Bereitstellung eines Raspberry Pi berücksichtigt werden möchten.
`,
        hash: 'no-iobroker',
    },
    {
        header: 'Ich benutze keine (unterstützte) Fritzbox. Kann ich trotzdem teilnehmen?',
        content: `
Leider ist die Teilnahme aktuell nur mit Fritzboxen möglich, welche die Paketmitschnittfunktion unterstützen.
`,
        hash: 'no-normal-fritzbox',
    },
    {
        header: 'Woher weiß ich, ob meine Geräte cloud-fähig sind?',
        content: `
Unter cloud-fähigen Geräten verstehen sich solche, die über das Internet kommunizieren, etwa um regelmäßigen Informationsaustausch mit der Cloud des Herstellers zu betreiben. Hierunter fallen viele der üblichen Smarthome-Geräte.

Sollten Sie nicht wissen, ob Ihre Geräte cloud-fähig sind, so können Sie dies über den KISSHome ioBroker-Adapter erfahren. 
Hierzu müssen Sie den Adapter in Betrieb nehmen und nach einigen Stunden der Datenübertragung erhalten Sie von uns eine E-Mail, in der wir Ihnen die Anzahl der cloud-fähigen Geräte nennen, von denen wir von Ihnen Daten bekommen haben.
Hierzu ist es wichtig, dass sie beim erstmaligen Einstellen des Mitschneidens sicherstellen, dass Ihre ausgewählten Geräte in dieser Zeit auch angeschaltet sind und benutzt werden. 

Bitte beachten Sie, dass die automatische Mail nur versandt wird, sobald wir ein Mindestmaß an Datenvolumen erhalten haben, um die korrekte Identifizierung zu ermöglichen.
`,
        hash: 'cloud-enabled-devices',
    },
    {
        header: 'Wer steht hinter dem KISSHOME-Projekt?',
        content: `
Das KISSHOME-Projekt ist ein Forschungsprojekt zwischen ARIC e.V., dem Institut für Internet-Sicherheit der Westfälischen Hochschule und dem Institut für Innovationsmarketing / Technische Universität Hamburg. Unser Ziel ist, Smarthome-Umgebungen sicherer zu machen. Weitere Informationen gibt es unter [https://kisshome.de](https://kisshome.de).
`,
        hash: 'who-is-who',
    },
    {
        header: 'Was erhalte ich für die Teilnahme an der Studie?',
        content: `
Nicht nur helfen Sie uns durch Ihre Teilnahme dabei, die Forschung im Bereich der Sicherheit von Smarthome-Umgebungen voranzutreiben, sondern es gibt auch Prämien, die wir Ihnen als Dankeschön geben können:

50€ für die Teilnahme, also das Zusenden von Daten mindestens dreier cloud-fähiger Geräte über einen Zeitraum von mindestens 6 Wochen. Diese gibt es als Gutschein von wunschgutschein.de, und kann für vielerlei Geschäfte, wie etwa Amazon, Media-Markt, DM, Ikea, Zalando oder Conrad umgetauscht werden.

Weiterhin ist es möglich, einen Raspberry Pi 5 zu erhalten. Da wir von diesen nur eine begrenzte Anzahl haben, sind diese vorzugsweise für diejenigen vorgesehen, die ioBroker nicht verwenden, und den Raspi daher für die Teilnahme benötigen, oder diejenigen, die eine besonders ausgeschmückte, für uns interessante Smarthome Umgebung haben.
`,
        hash: 'registration-rewards',
    },
    {
        header: 'Wie und wann erfahre ich, ob ich einen Gutschein für die Teilnahme erhalte?',
        content: `
Gutscheine gibt es für das Zusenden von Daten mindestens dreier cloud-fähiger Geräte über einen Zeitraum von mindestens 6 Wochen. 

Sollten Sie nicht wissen, ob Ihre Geräte cloud-fähig sind, schauen Sie unter der Frage: "Woher weiß ich, ob meine Geräte cloud-fähig sind?"
`,
        hash: 'voucher-rewards',
    },
    {
        header: 'Wie und wann erfahre ich, ob ich einen Raspberry Pi für die Teilnahme erhalte?',
        content: `
Da wir nur eine begrenzte Anzahl an Raspberry Pis zur Verfügung haben, können wir leider nicht jedem Teilnehmer einen zuschicken. Wird im Registrierungsformular entsprechend angegeben, dass ein Raspberry Pi 5 erwünscht ist, überprüfen wir Ihre Angaben und setzen uns unter Umständen mit Ihnen für die Auswahl in Kontakt.
`,
        hash: 'raspi-rewards',
    },
    {
        header: 'Ich habe einen Raspberry Pi erhalten. Welche Schritte muss ich nun befolgen?',
        content: `
1.
Zunächst muss der Raspberry Pi mit Gehäuse zusammengebaut werden und die SD Karte eingesetzt werden. Hierzu können die Schritte wie in folgender Anleitung befolgt werden: [http://www.youtube.com/watch?v=Z2Y0S5CHUbA&t=517s](http://www.youtube.com/watch?v=Z2Y0S5CHUbA&t=517s).

2.
Der Raspberry Pi wird mit dem Netzteil an den Strom und über das mitgelieferte Netzwerkkabel an einen der LAN-Ports Ihrer Fritz!Box angeschlossen.

3.
IoBroker kann nun im Browser unter [http://kisshome:8081](http://kisshome:8081) aufgerufen werden. Es sollte eine ioBroker-Willkommensseite 'admin.0' gezeigt werden. Bei Ersteinrichtung müssen die Installationsanweisungen befolgt werden. IoBroker ist nun einsatzbereit.

4.
Benutzen der Discovery-App (das Auge-Symbol in der oberen Leiste) um nach Adaptern für Ihre Smarthome-Geräte zu suchen und diese zu installieren. Dieser Schritt wird benötigt, um die Geräte für das Mitschneiden automatisch zu ermitteln. Alternativ können Geräte direkt über deren IP- oder MAC-Adresse angegeben werden.

5.
Der [KISSHome-Adapter muss aktualisiert](https://www.youtube.com/watch?v=OPHaLmrnAHE) werden. Dazu links unter Adapters/Adapter nach dem KISSHome-Adapter suchen (ins Suchfeld kisshome eingeben) und aktualisieren, siehe auch unser [YouTube-Video](https://www.youtube.com/watch?v=OPHaLmrnAHE). Evtl. steht eine neue Version bereit, wenn ja, diese bitte aktualisieren.

6.
In den Instanzeinstellungen des KISSHome-Adapter (Unter Instances/Instanzen, auf den Schraubenschlüssel des Adapters kisshome-research) müssen die Registrierungsemail und die Anmeldedaten der Fritz!box angegeben werden. Im Bereich Addresses/Adressen kann nun ausgewählt werden, von welchen Geräten mitgeschnitten werden soll. Ebenfalls können weitere Geräte direkt über deren IP- oder MAC-Adresse angegeben werden. Schließlich kann das Mitschneiden über den Knopf gestartet werden. Nach einen kurzen Moment sollte dies textuell bestätigt werden "Recording is running"/"Aufzeichnung läuft".
`,
        hash: 'getting_started',
    },
    {
        header: 'Meine Smarthome-Geräte sind im Gästenetz der FritzBox. Geht das?',
        content: `
Aus dem Gästenetz ist die interne API-Schnittstelle der FritzBox leider nicht erreichbar, sodass das Mitschneiden der Netzwerkkommunikation nicht funktioniert. Es gibt folgende Lösungsansätze:

1. Der Raspberry Pi mit ioBroker wird ins reguläre Netz (nicht das Gästenetz) gehängt. Die MAC-Adressen der Smarthome-Geräte müssen dann manuell in der Adapterkonfiguration eingetragen werden und das Interface in der Adapterkonfiguration muss von '1-lan' auf '1-guest' geändert werden. Das Mitschneiden funktioniert jetzt, allerdings kannst du Smarthome-Geräte, die lokal angesteuert werden nun nicht mit ioBroker steuern. Dies ist eine Limitierung des Gastnetzes der FritzBox.

2. Der Raspberry Pi wird per WLAN ins Gästenetz gehängt, und zusätzlich per LAN ins reguläre Netz (Non-Gästenetz). Leider ist so ein multi-homed Aufbau vergleichsweise komplex.

3. Die Smarthome-Geräte ziehen um in das reguläre Netz -- ja, das tut weh und ist keine gute Lösung.
`,
        hash: 'guestnetfb',
    },
    {
        header: 'Welche Daten werden gesammelt?',
        content: `
An den KISShome-Server werden folgende Daten gesendet:

- Die UUID der ioBroker-Instanz (um verschiedene ioBroker-Installationen auseinanderzuhalten)
- Die MAC-Adressen der Geräte, die zur Datenaufzeichnung konfiguriert sind
- Die IP-Adressen der Geräte, die zur Datenaufzeichnung konfiguriert sind
- Die IP-Adressen und die Ports, an welche die Geräte Daten senden
- Die Quell- und Ziel-Ports der Geräte, die zur Datenaufzeichnung konfiguriert sind
- Die TCP- und UDP-Header der Pakete. Alles, was nach den Headern kommt, wie zum Beispiel Nutzdaten, wird verworfen.
`,
        hash: 'data-collected',
    },
    {
        header: 'Wie lange werden die Daten gespeichert?',
        content: `
Die Daten werden nach 6 Monaten gelöscht und liegen anschließend nur noch in anonymisierter Form vor. Sie können jederzeit die Löschung Ihrer Daten beantragen.
Schreiben Sie uns hierzu von Ihrer registrierten E-Mail-Adresse an: [kisshome@internet-sicherheit.de](mailto:kisshome@internet-sicherheit.de).`,
        hash: 'data-retention',
    },
    {
        header: 'Wer hat Zugriff auf die Daten?',
        content: `
Nur Mitarbeitende des KISSHOME-Projekts im Institut für Internet-Sicherheit an der Westfälischen Hochschule Gelsenkirchen haben Zugriff auf die Daten. Die Daten werden zu keinem Zeitpunkt an Dritte weitergegeben.
`,
        hash: 'data-access',
    },
    {
        header: 'Wie ist das Feld "Schnittstelle" zu verstehen?',
        content: `
Die FritzBox unterstützt die Aufzeichnung von Netzwerkverkehr über mehrere Schnittstellen im PCAP-Format. Jede Schnittstelle repräsentiert dabei einen spezifischen Bereich des internen Netzwerks bzw. an Kommunikationswegen. Typische Schnittstellennamen wie 1-lan beziehen sich auf ausgehende Netzwerkkommunikation in Richtung Internet, während es auch Schnittstellen für die physikalischen LAN-Ports, WLAN  oder die WAN-Verbindung (Internet) gibt. 

Die Aufzeichnung ermöglicht es technisch versierten Nutzern, Netzwerkpakete zu analysieren, um detaillierte Informationen über den Datenverkehr zu erhalten. Wir nutzen dies für die Sicherheitsanalysen. Erfahrungsgemäß zeichnet die Schnittstelle 1-lan Netzwerkverkehr aus dem LAN und WLAN in Richtung Internet auf und ist damit für unsere Zwecke am besten geeignet. Sollten Sie sich mit Ihrem Netzwerk besonders gut auskennen oder bestimmte Netzbereiche für die Smarthome-Geräte verwenden, könnten Sie hier auch eine andere Schnittstelle angeben. 
`,
        hash: 'interface',
    },
    {
        header: 'Sonstige Fragen zur Teilnahme an der Studie oder zum KISSHOME Projekt?',
        content: `
Unklarheiten oder weitere Fragen rund um diese Studie, den Datenschutz, unsere Forschungsziele, usw. können gerne direkt an uns unter [kisshome@internet-sicherheit.de](mailto:kisshome@internet-sicherheit.de) gestellt werden. 
`,
        hash: 'other-questions',
    },
];

const Faq = (): React.JSX.Element => {
    const md = new MarkdownIt({ html: true });
    const page = window.location.hash.split('/')[2];
    const [activeKey, setActiveKey] = React.useState<number | null>(
        page ? faqBlocks.findIndex(block => block.hash === page) : null,
    );

    useEffect(() => {
        if (activeKey !== -1 && activeKey !== null) {
            setTimeout(() => {
                const el = document.getElementById(`faq-${activeKey}`);
                if (el) {
                    const y = el.getBoundingClientRect().top + window.scrollY - 105;

                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 24,
            }}
        >
            <h3>Häufig gestellte Fragen</h3>
            <Accordion defaultActiveKey={activeKey !== null ? activeKey.toString() : undefined}>
                {faqBlocks.map((block, index) => (
                    <Accordion.Item
                        id={`faq-${index}`}
                        eventKey={index.toString()}
                        key={index.toString()}
                    >
                        <Accordion.Header
                            onClick={() => {
                                setActiveKey(index);
                                window.location.hash = `#/faq/${block.hash}`;
                            }}
                        >
                            <span style={{ fontWeight: 'bold' }}>{block.header}</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div
                                style={{ width: '100%', textAlign: 'justify' }}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: md.render(block.content) || 'Lade...' }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

export default Faq;
